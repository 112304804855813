<template>
  <div class="flex">
    <div
      class="search-full-container w-full h-full absolute left-0 top-0"
      :class="{ flex: showFullSearch }"
      v-show="showFullSearch"
    >
      <vx-auto-suggest
        ref="navbarSearch"
        :autoFocus="showFullSearch"
        :data="dataSearched"
        search_key="title"
        background-overlay
        class="w-full"
        inputClassses="w-full vs-input-no-border vs-input-no-shdow-focus"
        icon="SearchIcon"
        placeholder="Pesquisar Propostas"
        @input="hnd_search_query_update"
        @selected="selected"
        @closeSearchbar="showFullSearch = false"
      >
        <template v-slot:group="{ group_name }">
          <p class="font-semibold text-primary">{{ group_name | title }}</p>
        </template>

        <!-- Pages Suggestion -->
        <template v-slot:item="slotScope">
          <div class="block-info">
            <div class="info">
              <h4 class="text-primary font-semibold">
                {{ slotScope.suggestion.Number }}
              </h4>
              <p>
                Data da Proposta:
                {{
                  new Date(slotScope.suggestion.CreateDate)
                    | dateFormat("DD/MM/YYYY")
                }}
              </p>
            </div>
            <div class="info">
              <p>
                <span>
                  {{ slotScope.suggestion.ModalityGroupName }} /
                  {{ slotScope.suggestion.ModalityName }}</span
                >
              </p>
            </div>
            <div class="info">
              <p>
                <span class="text-primary font-semibold mr-3">Tomador:</span>
                <span>
                  {{ slotScope.suggestion.PolicyHolderName }} /
                  {{ slotScope.suggestion.PolicyHolderCpfCnpj }}</span
                >
              </p>
            </div>
            <div class="info">
              <p>
                <span class="text-primary font-semibold mr-3">Segurado:</span>
                <span>
                  {{ slotScope.suggestion.InsuredName }} /
                  {{ slotScope.suggestion.InsuredCpfCnpj }}</span
                >
              </p>
            </div>
            <div class="info">
              <p>
                <span class="text-primary font-semibold mr-3">Status:</span>
                <span> {{ slotScope.suggestion.Status }}</span>
              </p>
            </div>
          </div>
        </template>
      </vx-auto-suggest>

      <div class="absolute right-0 h-full z-50">
        <feather-icon
          icon="XIcon"
          class="px-4 cursor-pointer h-full close-search-icon"
          @click="showFullSearch = false"
        />
      </div>
    </div>
   
    <button  
      @click="showFullSearch = true" 
      class="onpoint-magnifying-glass search-button">
    </button>

  </div>
</template>

<script>
import VxAutoSuggest from "@/components/vx-auto-suggest/VxAutoSuggest.vue";
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";

export default {
  components: {
    VxAutoSuggest
  },
  data() {
    return {
      dataSearched: [],
      showFullSearch: false
    };
  },
  methods: {
    ...mapActions("propostas-module", ["buscarPropostasCorretoraPorTexto"]),
    selected(proposal) {
      if (proposal.StatusId === 1) {
        //COTAÇÃO
        this.$router.push({
          name: "editar-proposta",
          params: { propostaUniqueId: proposal.UniqueId }
        });
      } else {
        this.$router.push({
          name: "timeline-proposta",
          params: { propostaUniqueId: proposal.UniqueId }
        });
      }
    },
    clearAll() {},
    search: _.debounce(async function(query) {
      // Show overlay if any character is entered
      this.dataSearched = [];
      if (!query || query.length < 3) {
        return this.clearAll();
      }
      await this.$onpoint.loading( async () => {
        return await this.buscarPropostasCorretoraPorTexto(query)
          .then(response => {
            if ((response || []).length > 9)
              this.dataSearched.push({ name: "Propostas", data: response });
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.Message);
          });
      });
    }, 1000),
    hnd_search_query_update(query) {
      this.dataSearched = [];
      this.$store.commit("TOGGLE_CONTENT_OVERLAY", !!query);
      this.search(query);
    }
  }
};
</script>
<style lang="scss">
.block-info {
  border-left: 5px solid #ff9100;
  padding-left: 20px;
  .info {
    max-width: 60%;
  }
  .info h4 {
    font-size: 1.15rem;
    font-weight: bold;
  }
  .info p {
    font-size: 1.05rem;
  }
  .info span {
    font-size: 1rem;
  }
}

.search-button{
  border: none;
  background-color: transparent;
  font-size: 24px;
}
</style>
