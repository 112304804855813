import Vue from "vue";
let signalR = require("@aspnet/signalr");
import instance from "@/axios";
export default {
  connection: null,
connect() {
    this.connection.start().then(
      () => {
        var _token = JSON.parse(localStorage.getItem("auth_data"));
        this.connection.invoke("Start", _token.access_token);
      },
      () => {
        setTimeout(() => {
          this.connect();
        }, 20000);
      }
    );
    this.connection.on("ReceiveNotificationGroup", function(message) {
      let resultado = JSON.parse(message);
      Vue.prototype.$eventHub.$emit("play-notification", resultado);
      
    });
    this.connection.onclose(() => {
      this.connect();
    });
  },

  start() {
    return false
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(
        "https://onpoint-notification-api-dev.azurewebsites.net/hubs/notifications"
      )
      .build();
    setTimeout(() => {
      this.connect();
    }, 1000);
  },

 
};
