<template>
  <img :src="logoUrl" />
</template>
<script>

import environment from "../../environment";
export default {
  data () {
    return {
      applicationConfig:{}
    };
  },
  computed: {
    logoUrl:{
      get () {
        const url = (((this.applicationConfig || {}).layout || {}).common || {}).logoWhite;
        if (url) return url;
        else return require("@/assets/images/logo/logo-onpoint-negativo.png");
      }
    }
  },
  beforeMount () {
    this.applicationConfig = environment.getApplicationConfig();
  }
};
</script>