<template>
  <div
    class="vs-sidebar--item"
    :class="[
      {'vs-sidebar-item-active'            : activeLink},
      {'disabled-item pointer-events-none' : isDisabled}
    ]" >
      <a @click="goTo(to)" :class="[{'router-link-active': activeLink}]">
          <i :class="icon"></i>
          <slot />
      </a>

  </div>
</template>

<script>
export default {
  name: "v-nav-menu-item",
  props: {
    icon        : { type: String,                 default: ""               },
    iconSmall   : { type: Boolean,                default: false            },
    iconPack    : { type: String,                 default: "material-icons" },
    href        : { type: [String, null],         default: "#"              },
    to          : { type: [String, Object, null], default: null             },
    slug        : { type: String,                 default: null             },
    index       : { type: [String, Number],       default: null             },
    featherIcon : { type: Boolean,                default: true             },
    target      : { type: String,                 default: "_self"          },
    isDisabled  : { type: Boolean,                default: false            }
  },
  computed: {
    activeLink () {
      return !!((this.to === this.$route.path || this.$route.meta.parent === this.slug) && this.to);
    }
  },
  methods:{
    goTo(path){
      this.$appInsights.trackEvent({ name: `go-to-${this.slug}`, properties: { place: 'menu-lateral', action: "button-click", slug: `go-to-${this.slug}` }});
      this.$router.push(path);
    }
  }
};

</script>

