var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showFullSearch,
            expression: "showFullSearch",
          },
        ],
        staticClass:
          "search-full-container w-full h-full absolute left-0 top-0",
        class: { flex: _vm.showFullSearch },
      },
      [
        _c("vx-auto-suggest", {
          ref: "navbarSearch",
          staticClass: "w-full",
          attrs: {
            autoFocus: _vm.showFullSearch,
            data: _vm.dataSearched,
            search_key: "title",
            "background-overlay": "",
            inputClassses: "w-full vs-input-no-border vs-input-no-shdow-focus",
            icon: "SearchIcon",
            placeholder: "Pesquisar Propostas",
          },
          on: {
            input: _vm.hnd_search_query_update,
            selected: _vm.selected,
            closeSearchbar: function ($event) {
              _vm.showFullSearch = false
            },
          },
          scopedSlots: _vm._u([
            {
              key: "group",
              fn: function ({ group_name }) {
                return [
                  _c("p", { staticClass: "font-semibold text-primary" }, [
                    _vm._v(_vm._s(_vm._f("title")(group_name))),
                  ]),
                ]
              },
            },
            {
              key: "item",
              fn: function (slotScope) {
                return [
                  _c("div", { staticClass: "block-info" }, [
                    _c("div", { staticClass: "info" }, [
                      _c("h4", { staticClass: "text-primary font-semibold" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(slotScope.suggestion.Number) +
                            "\n            "
                        ),
                      ]),
                      _c("p", [
                        _vm._v(
                          "\n              Data da Proposta:\n              " +
                            _vm._s(
                              _vm._f("dateFormat")(
                                new Date(slotScope.suggestion.CreateDate),
                                "DD/MM/YYYY"
                              )
                            ) +
                            "\n            "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "info" }, [
                      _c("p", [
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(slotScope.suggestion.ModalityGroupName) +
                              " /\n                " +
                              _vm._s(slotScope.suggestion.ModalityName)
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "info" }, [
                      _c("p", [
                        _c(
                          "span",
                          { staticClass: "text-primary font-semibold mr-3" },
                          [_vm._v("Tomador:")]
                        ),
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(slotScope.suggestion.PolicyHolderName) +
                              " /\n                " +
                              _vm._s(slotScope.suggestion.PolicyHolderCpfCnpj)
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "info" }, [
                      _c("p", [
                        _c(
                          "span",
                          { staticClass: "text-primary font-semibold mr-3" },
                          [_vm._v("Segurado:")]
                        ),
                        _c("span", [
                          _vm._v(
                            "\n                " +
                              _vm._s(slotScope.suggestion.InsuredName) +
                              " /\n                " +
                              _vm._s(slotScope.suggestion.InsuredCpfCnpj)
                          ),
                        ]),
                      ]),
                    ]),
                    _c("div", { staticClass: "info" }, [
                      _c("p", [
                        _c(
                          "span",
                          { staticClass: "text-primary font-semibold mr-3" },
                          [_vm._v("Status:")]
                        ),
                        _c("span", [
                          _vm._v(" " + _vm._s(slotScope.suggestion.Status)),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              },
            },
          ]),
        }),
        _c(
          "div",
          { staticClass: "absolute right-0 h-full z-50" },
          [
            _c("feather-icon", {
              staticClass: "px-4 cursor-pointer h-full close-search-icon",
              attrs: { icon: "XIcon" },
              on: {
                click: function ($event) {
                  _vm.showFullSearch = false
                },
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("button", {
      staticClass: "onpoint-magnifying-glass search-button",
      on: {
        click: function ($event) {
          _vm.showFullSearch = true
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }