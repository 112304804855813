<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper" :class="classObj">
      <vs-navbar
        class="vx-navbar navbar-custom navbar-skelton"
        :color="navbarColorLocal"
        :class="textColor"
      >
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon
          class="sm:inline-flex lp:hidden cursor-pointer p-2"
          icon="MenuIcon"
          @click.stop="showSidebar"
        />

        <search-bar class="mr-4" />

        <vs-spacer />
        <profile-drop-down class="mr-2 mt-1 md:mt-0 md:mr-8" />
        <notification-drop-down
          :unreadNotifications="recentProposals"
          :OldNotifications="oldNotification"
          title="Notificações"
          message="Você não possui nenhuma notificação."
        >
          <feather-icon
            icon="BellIcon"
            class="cursor-pointer mt-1 mr-4 notfication"
            :badge="recentProposals.length"
          />
        </notification-drop-down>

        <!-- <bookmarks :navbarColor="navbarColor" :favorites="favoritesPropostas" v-if="windowWidth >= 360"/> -->
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import Bookmarks from "./components/Bookmarks.vue";
import SearchBar from "./components/SearchBar.vue";
import NotificationDropDown from "./components/NotificationDropDown.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
import Vue from "vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
//import { mapGetters } from 'vuex'
import axiosInstance from "../../../axios";
import signalrHub from "../../../services/signalrHub.js";
export default {
  name: "the-navbar-vertical",
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
  },
  components: {
    Bookmarks,
    SearchBar,
    NotificationDropDown,
    ProfileDropDown,
  },
  data() {
    return {
      favoritesPropostas: [],
      dadosPropostas: [],
      recentProposals: [],
      oldNotification: [],
    };
  },
  created() {
    this.$eventHub.$on("play-notification", this.showNotification);
    this.recentProposals =
      this.$store.state["auth"].notificacoes;
    this.oldNotification = this.$store.state["auth"].all;
  },
  beforeDestroy() {
    this.$eventHub.$off("play-notification");
  },
  mounted() {
    signalrHub.start();
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor !== "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor !== "#fff" && this.$store.state.theme !== "dark"),
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth === "default") return "navbar-default";
      else if (this.verticalNavMenuWidth === "reduced") return "navbar-reduced";
      else if (this.verticalNavMenuWidth) return "navbar-full";
    },
  },
  methods: {
    ...mapMutations("auth", [
      "addNotification",
    ]),

    showNotification(dados) {
      this.addNotification(dados);
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
  },
};
</script>
