var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isAuthenticated
    ? _c(
        "div",
        { staticClass: "the-navbar__user-meta flex items-center" },
        [
          _c(
            "vs-dropdown",
            {
              staticClass: "cursor-pointer",
              attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
            },
            [
              _c("button", [
                _c("i", { staticClass: "onpoint-user user-button" }),
              ]),
              _c("vs-dropdown-menu", { staticClass: "vx-navbar-dropdown" }, [
                _c(
                  "ul",
                  { staticClass: "m-0 p-0" },
                  [
                    _vm.profileHelpers.checkPermission([
                      _vm.profileHelpers.menuFuncionalitiesEnum
                        .GERENCIAR_CORRETORA,
                      _vm.profileHelpers.menuFuncionalitiesEnum
                        .VISUALIZAR_CORRETORA,
                    ])
                      ? _c(
                          "li",
                          {
                            staticClass:
                              "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                            on: {
                              click: function ($event) {
                                return _vm.goToPerfilCorretora()
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass: "onpoint-user",
                              staticStyle: { position: "relative", top: "2px" },
                            }),
                            _c("span", { staticClass: "ml-2" }, [
                              _vm._v("Perfil"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    (_vm.advisorInfo || {}).PersonUniqueId &&
                    !_vm.profileHelpers.isAdvisor()
                      ? [
                          _c("vs-divider", { staticClass: "m-1" }),
                          _c(
                            "li",
                            {
                              staticClass:
                                "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                              on: {
                                click: function ($event) {
                                  return _vm.returnAdivisor()
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "onpoint-user",
                                staticStyle: {
                                  position: "relative",
                                  top: "2px",
                                },
                              }),
                              _c("span", { staticClass: "ml-2" }, [
                                _vm._v("Voltar para Perfil Assessoria"),
                              ]),
                            ]
                          ),
                        ]
                      : _vm._e(),
                    _c("vs-divider", { staticClass: "m-1" }),
                    !_vm.profileHelpers.isInsurance() &&
                    !_vm.profileHelpers.isAdvisor()
                      ? _c(
                          "div",
                          [
                            _c(
                              "li",
                              {
                                staticClass:
                                  "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                                on: { click: _vm.goToMeusTermosAceite },
                              },
                              [
                                _c("i", {
                                  staticClass: "onpoint-file-text",
                                  staticStyle: {
                                    position: "relative",
                                    top: "2px",
                                  },
                                }),
                                _c("span", { staticClass: "ml-2" }, [
                                  _vm._v("Meus termos de aceite"),
                                ]),
                              ]
                            ),
                            _c("vs-divider", { staticClass: "m-1" }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "li",
                      {
                        staticClass:
                          "flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white",
                        on: { click: _vm.onLogout },
                      },
                      [
                        _c("i", {
                          staticClass: "onpoint-sign-out",
                          staticStyle: { position: "relative", top: "2px" },
                        }),
                        _c("span", { staticClass: "ml-2" }, [
                          _vm._v("Sair do sistema"),
                        ]),
                      ]
                    ),
                    _c("vs-divider", { staticClass: "m-1" }),
                  ],
                  2
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-right leading-tight hidden sm:block" },
            [
              _c("p", { staticClass: "font-semibold" }, [
                _vm._v(_vm._s(_vm.userInfo.DisplayName || "Desconhecido")),
              ]),
              _c("small", [
                _vm._v(_vm._s(_vm.userInfo.ProfileName || "Desconhecido")),
              ]),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }