<template>
  <div class="vx-auto-suggest">
    <div class="flex items-center relative">
      <!-- Input -->
      <vs-input
        ref="input"
        :placeholder="placeholder"
        :class="inputClassses"
        class="z-50"
        icon-pack="feather"
        icon="icon-search"
        icon-no-border
        v-model="searchQuery"
        @keyup.esc="escPressed"
        @keyup.up="increaseIndex(false)"
        @keyup.down="increaseIndex"
        @keyup.enter="suggestionSelected"
        @focus="updateInputFocus"
        @blur="updateInputFocus(false)"
      />
    </div>

    <!-- Group List -->
    <ul
      ref="scrollContainer"
      :class="{ hidden: !inputFocused }"
      class="auto-suggest-suggestions-list z-50 rounded-lg mt-2 shadow-lg overflow-x-hidden"
      @mouseenter="insideSuggestions = true"
      @mouseleave="insideSuggestions = false"
      @focus="updateInputFocus"
      @blur="updateInputFocus(false)"
      tabindex="-1"
    >
      <li
        ref="grp_list"
        v-for="(item, grp_index) in filteredData"
        :key="grp_index"
        class="auto-suggest__suggestion-group-container"
      >
        <!-- Group Header -->
        <p
          class="auto-suggest__suggestion-group-title pt-3 pb-1 px-4"
          v-if="!hideGroupTitle"
        >
          <slot name="group" :group_name="item.name"></slot>
        </p>

        <!-- Suggestion List of each group -->
        <ul>
          <li
            v-for="(suggestion, index) in item.data"
            :key="index"
            class="auto-suggest__suggestion-group__suggestion py-3 px-4 cursor-pointer"
            :class="{
              'vx-auto-suggest__current-selected':
                currentSelected == `${grp_index}.${index}`
            }"
            @mouseenter="currentSelected = `${grp_index}.${index}`"
            @click="suggestionSelected(suggestion)"
          >
            <slot name="item" :suggestion="suggestion"></slot>
          </li>

          <!--<li class="auto-suggest__suggestion-group__suggestion py-3 px-4 no-results" v-if="!suggestion_list.length && searchQuery">
              <slot name="noResult" :group_name="grp_name">
                  <p>Nenhum resultado encontrado.</p>
              </slot>
            </li>-->
        </ul>
      </li>
      <li
        ref="grp_list"
        v-if="(data || []).length == 0 && searchQuery"
        class="auto-suggest__suggestion-group-container"
      >
        <ul>
          <li
            class="auto-suggest__suggestion-group__suggestion py-3 px-4 no-results"
          >
            <slot name="noResult">
              <p>Nenhum resultado encontrado.</p>
            </slot>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "Buscar.."
    },
    data: {
      type: Array,
      required: true
    },
    initalData: {
      type: Array,
      default: () => []
    },
    inputClassses: {
      type: [String, Object, Array]
    },
    autoFocus: {
      type: Boolean,
      default: false
    },
    showPinned: {
      type: Boolean,
      default: false
    },
    searchLimit: {
      type: Number,
      default: 4
    },
    hideGroupTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      searchQuery: "",
      filteredData: {},
      currentSelected: -1,
      inputFocused: false,
      insideSuggestions: false
    };
  },
  watch: {
    // UPDATE SUGGESTIONS LIST
    searchQuery(val) {
      this.$emit("input", val);

      if (val === "") {
        this.inputInit();
      } else {
        this.currentSelected = -1;
      }
    },
    autoFocus(val) {
      if (val) this.focusInput();
      else this.searchQuery = "";
    },
    data(val) {
      this.filteredData = this.data;
    }
  },
  methods: {
    escPressed() {
      this.$emit("closeSearchbar");
      this.searchQuery = "";
      this.filteredData = [];
    },
    inputInit() {
      if ((this.initalData || []).length === 0) {
        this.filteredData = [];
      } else {
        this.filteredData = this.initalData;
      }
    },
    updateInputFocus(val = true) {
      if (val) {
        if (this.searchQuery === "") this.inputInit();
        setTimeout(() => {
          this.inputFocused = true;
        }, 100);
      } else {
        if (this.insideSuggestions) return;
        setTimeout(() => {
          this.inputFocused = false;
        }, 100);
        this.escPressed();
      }
    },
    suggestionSelected(item) {
      this.$emit("selected", item);
      this.escPressed();
      /**if (this.currentSelected > -1) {

        const [grp_index, item_index] = this.currentSelected.split('.')

        const grp_of_selected_item = Object.keys(this.data)[grp_index]
        const selected_item = this.filteredData[grp_of_selected_item][item_index]


        this.searchQuery = ''
      }**/
    },
    increaseIndex(val = true) {},
    focusInput() {
      this.$refs.input.$el.querySelector("input").focus();
    }
  },
  mounted() {
    if (this.autoFocus) this.focusInput();
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/components/vxAutoSuggest.scss";
</style>
