var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "vs-dropdown",
        {
          staticClass: "cursor-pointer",
          attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
          on: {
            click: function ($event) {
              return _vm.seeNotifications()
            },
          },
        },
        [
          _c("button", [
            _c("i", { staticClass: "onpoint-bell notification-button" }),
          ]),
          _c(
            "vs-dropdown-menu",
            {
              staticClass:
                "notification-dropdown dropdown-custom vx-navbar-dropdown",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "notification-top text-start p-5 bg-primary text-white",
                },
                [
                  _c("h4", { staticClass: "text-white" }, [
                    _vm._v(_vm._s(_vm.title)),
                  ]),
                ]
              ),
              _vm.unreadNotifications.length > 0
                ? [
                    _c(
                      _vm.scrollbarTag,
                      {
                        key: _vm.$vs.rtl,
                        ref: "mainSidebarPs",
                        tag: "component",
                        staticClass: "scroll-area--nofications-dropdown p-0",
                        attrs: { settings: _vm.settings },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "bordered-items p-0" },
                          _vm._l(_vm.unreadNotifications, function (ntf) {
                            return _c(
                              "li",
                              {
                                key: ntf.index,
                                staticClass:
                                  "notification cursor-pointer w-full",
                              },
                              [
                                ntf.Message
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-between px-4 py-4",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-col items-start",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    new Date(ntf.CreateDate),
                                                    "DD/MM/YYYY - HH:mm"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "font-semibold text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(ntf.Message) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "botoes" },
                                          [
                                            _vm.perfilHelpers.isInsurance() &&
                                            ntf.CardUniqueId
                                              ? _c("el-button", {
                                                  attrs: {
                                                    type: "primary",
                                                    icon: "el-icon-share",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openCard(ntf)
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.perfilHelpers.isBroker() &&
                                            ntf.ProposalUniqueId
                                              ? _c("el-button", {
                                                  attrs: {
                                                    type: "primary",
                                                    icon: "el-icon-share",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openProposal(
                                                        ntf
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-between px-4 py-4",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "flex flex-col items-start",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    new Date(
                                                      ntf.id.creationTime
                                                    ),
                                                    "DD/MM/YYYY - HH:mm"
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "font-semibold text-sm",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(ntf.message) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "botoes" },
                                          [
                                            _vm.perfilHelpers.isInsurance() &&
                                            ntf.cardUniqueId
                                              ? _c("el-button", {
                                                  attrs: {
                                                    type: "primary",
                                                    icon: "el-icon-share",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openCard2(ntf)
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm.perfilHelpers.isBroker() &&
                                            ntf.proposalUniqueId
                                              ? _c("el-button", {
                                                  attrs: {
                                                    type: "primary",
                                                    icon: "el-icon-share",
                                                    circle: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openProposal2(
                                                        ntf
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      _vm.scrollbarTag,
                      {
                        key: _vm.$vs.rtl,
                        ref: "mainSidebarPs",
                        tag: "component",
                        staticClass: "scroll-area--nofications-dropdown p-0",
                        attrs: { settings: _vm.settings },
                      },
                      [
                        _c("ul", { staticClass: "bordered-items p-0" }, [
                          _c("li", { staticClass: "cursor-pointer w-full" }, [
                            _c(
                              "div",
                              { staticClass: "flex justify-between px-4 py-4" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "flex flex-col items-start" },
                                  [_vm._v("Sem mensagens")]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ],
              _c(
                "div",
                {
                  staticClass: "outras-notificacoes",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.showNotificacoes()
                    },
                  },
                },
                [_vm._v("\n        Ver lidas\n      ")]
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          staticClass: "termos-modal",
          attrs: { active: _vm.modal, title: "Mensagens lidas" },
          on: {
            "update:active": function ($event) {
              _vm.modal = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col items-center pt-4" },
            [
              _vm.OldNotifications.length > 0
                ? [
                    _c(
                      _vm.scrollbarTag,
                      {
                        key: _vm.$vs.rtl,
                        ref: "mainSidebarPs",
                        tag: "component",
                        staticClass: "scroll-area--nofications-dropdown p-0",
                        attrs: { settings: _vm.settings },
                      },
                      [
                        _c(
                          "ul",
                          { staticClass: "bordered-items p-0" },
                          _vm._l(_vm.OldNotifications, function (ntf) {
                            return _c(
                              "li",
                              {
                                key: ntf.index,
                                staticClass:
                                  "notification cursor-pointer w-full",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex justify-between px-4 py-4",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex flex-col items-start",
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm._f("dateFormat")(
                                                new Date(ntf.id.creationTime),
                                                "DD/MM/YYYY - HH:mm"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "font-semibold text-sm",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(ntf.message) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "p",
                                          {
                                            staticClass:
                                              "font-semibold text-sm",
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Status: " +
                                                _vm._s(ntf.status) +
                                                "\n                  "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "botoes" },
                                      [
                                        _vm.perfilHelpers.isInsurance() &&
                                        ntf.cardUniqueId
                                          ? _c("el-button", {
                                              attrs: {
                                                type: "primary",
                                                icon: "el-icon-share",
                                                circle: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openCard2(ntf)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm.perfilHelpers.isBroker() &&
                                        ntf.proposalUniqueId
                                          ? _c("el-button", {
                                              attrs: {
                                                type: "primary",
                                                icon: "el-icon-share",
                                                circle: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openProposal2(ntf)
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                : [
                    _c(
                      _vm.scrollbarTag,
                      {
                        key: _vm.$vs.rtl,
                        ref: "mainSidebarPs",
                        tag: "component",
                        staticClass: "scroll-area--nofications-dropdown p-0",
                        attrs: { settings: _vm.settings },
                      },
                      [
                        _c("ul", { staticClass: "bordered-items p-0" }, [
                          _c(
                            "li",
                            {
                              staticClass: "notification cursor-pointer w-full",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "flex justify-between px-4 py-4",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex flex-col items-start",
                                    },
                                    [_vm._v("Sem mensagens")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ],
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }